/**
 * @function
 * @arg {{ [key: string]: any }} obj - a plain object. If value is another object, key is concatenated to internal keys
 * @arg {string} prefix - used to add a prefix to keys
 * @return {string} - query params with '&' and without '?'
 */
const parse = (obj, prefix) => {
  const entries = Object.entries(obj);
  return entries
    .filter(([, value]) => Array.isArray(value) ? value.length > 0 : !!value)
    .map(([key, value]) => {
      const snakeCase = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      const safeKey = prefix ? `${prefix}[${snakeCase}]` : snakeCase;

      if (typeof value === 'object' && !Array.isArray(value)) {
        return parse(value, safeKey);
      }
      
      const safeValue = Array.isArray(value) ? value.join(';') : value;
      return `${safeKey}=${safeValue}`;
    })
    .filter(query => !!query)
    .join('&');
};

export default parse;
